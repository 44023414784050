<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <!--  -->
      <v-container>
        <v-row>
          <v-col md="2" class="mr-2" v-if="returnAbility('term:store')">
            <v-dialog v-model="newTermDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="clearData" v-on="on" v-bind="attrs" color="green" class="white--text">
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t('add new term') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  <h2> {{ $t('add new term') }}</h2>
                </v-card-title>
                <v-card-text>
                  <v-text-field :label="$t('name')" v-model="newTerm.name" outlined dense color="#757575"
                    type="text"></v-text-field>
                  <v-autocomplete v-model="newTerm.term_type_id" outlined dense :items="termTypes"
                    :label="$t('term type')" item-text="name" item-value="id"></v-autocomplete>
                  <v-autocomplete v-model="newTerm.educational_year_id" outlined dense :items="educationalYears"
                    :label="$t('educational year')" item-text="name" item-value="id"></v-autocomplete>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn color="green" class="white--text" @click="submitNewTerm" :loading="submitBtnLoading"
                    :disabled="disableSubmitBtn">{{ $t('storage') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
           
          </v-col>
          <v-col md="2" class="mr-2">
            <v-dialog v-model="copyTermDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="clearData" v-on="on" v-bind="attrs" color="green" class="white--text">
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t('Copy Term') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  <h2> {{ $t('Copy Term') }}</h2>
                </v-card-title>
                <v-card-text>
                  
                  <v-autocomplete v-model="copyTerm.to" outlined dense :items="terms"
                    :label="$t('Term To Be Copied')" item-text="name" item-value="id"></v-autocomplete>
                 
                </v-card-text>
                <v-checkbox :dark="$store.state.isDarkMode" :label="$t('calsses')"  v-model="copyTerm.grades" :true-value="1" :false-value="0"
                  class="mx-4 mb-4"
                  hide-details
             ></v-checkbox>
             <v-checkbox :dark="$store.state.isDarkMode" :label="$t('education class')"  v-model="copyTerm.edu_classes" :true-value="1" :false-value="0"
                  class="mx-4 mb-4"
                  hide-details
             ></v-checkbox>
             <v-checkbox :dark="$store.state.isDarkMode" :label="$t('students')"  v-model="copyTerm.students" :true-value="1" :false-value="0"
                  class="mx-4 mb-4"
                  hide-details
             ></v-checkbox>
             <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Subject Partitions')"  v-model="copyTerm.subject_partitions" :true-value="1" :false-value="0"
                  class="mx-4 mb-4"
                  hide-details
             ></v-checkbox>
                <v-card-actions class="justify-center">
                  <v-btn color="green" class="white--text" @click="submitCopyTerm" :loading="submitBtnLoading"
                    :disabled="disableCopyBtn">{{ $t('Copy') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col md="2">
            <v-text-field v-model="nameSearch" :label="$t('search by name')" dense outlined hide-details></v-text-field>
          </v-col>
          <v-col md="2">
            <v-autocomplete hide-details v-model="searchTermTypeId" outlined dense :items="termTypes"
              :label="$t('search by term type')" item-text="name" item-value="id" clearable></v-autocomplete>
          </v-col>
          <v-col md="3" class="d-flex justify-center align-center">
            <v-autocomplete v-model="searchEducationalYearId" outlined dense hide-details :items="educationalYears"
              :label="$t('search by educational year')" item-text="name" item-value="id" clearable></v-autocomplete>
            <v-icon class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <!--  -->
      <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)" :enableDelete="false">
        <template slot="editFeature" slot-scope="{ row }">
          <app-edit-term-modal v-if="returnAbility('term:update')" @recordUpdated="fetchData"
            :recordData="row"></app-edit-term-modal>
            <DeleteTerm path="term" :id="row.id" @record-deleted="fetchData"/>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import editTermModal from "./editTermModal.vue";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import DeleteTerm from "../../../components/core/delete.vue";
export default {
  components: {
    appEditTermModal: editTermModal,
    DeleteTerm,
  },
  data() {
    return {
      nameSearch: "",
      searchTermTypeId: null,
      searchEducationalYearId: null,
      newTermDialog: false,
      copyTermDialog: false,
      submitBtnLoading: false,
      newTerm: {
        name: "",
        term_type_id: null,
        educational_year_id: null,
      },
      copyTerm: {
        to: null,
        grades: 0,
        edu_classes: 0,
        students: 0,
        subject_partitions: 0,
      },
    };
  },
  watch: {
    "copyTerm.edu_classes": function () {
      if(this.copyTerm.edu_classes){
        this.copyTerm.grades = 1;
      }
      },
      "copyTerm.students": function () {
      if(this.copyTerm.students){
        this.copyTerm.grades = 1;
        this.copyTerm.edu_classes = 1;
      }
      },
  },
  methods: {
    ...mapActions({
      fetchEduYears: "educationalYear/fetchAllEduYears",
      fetchTermTypes: "termType/fetchAllTermTypes",
      fetchTerms: "terms/fetchTerms",
    }),
    clearData(){
      this.newTerm = {
        name: "",
        term_type_id: null,
        educational_year_id: null,
      };
      this.copyTerm = {
        to: null,
        grades: 0,
        edu_classes: 0,
        students: 0,
        subject_partitions: 0,
      };
      this.submitBtnLoading = false;
    },
    async submitNewTerm() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/term", this.newTerm);
        this.$Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData(1);
        this.newTermDialog = false;
      } catch (err) {
      } finally {
       this.clearData();
      }
    },
    async submitCopyTerm() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/term/copy", this.copyTerm);
        this.$Notifications(
          "تمت عملية النسخ بنجاح",
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData(1);
        this.copyTermDialog = false;
      } catch (err) {
      } finally {
        this.clearData();
      }
    },
    fetchData(paginationValue) {
      this.fetchTerms({
        paginationValue,
        nameSearch: this.nameSearch,
        searchTermTypeId: this.searchTermTypeId,
        searchEducationalYearId: this.searchEducationalYearId,
      });
    },
  },
  computed: {
    ...mapGetters({
      educationalYears: "educationalYear/getEducationalYears",
      termTypes: "termType/getTermTypes",
      tableOptions: "terms/getTableOptions",
      terms: "terms/getTerms",
    }),
    disableSubmitBtn() {
      if (
        !this.newTerm.name ||
        !this.newTerm.term_type_id ||
        !this.newTerm.educational_year_id
      ) {
        return true;
      } else {
        return false;
      }
    },
    disableCopyBtn() {
      if (
        !this.copyTerm.to 
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.fetchTermTypes();
    this.fetchEduYears();
    this.fetchData(1);
  },
};
</script>

<style>

</style>